import { Empty, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const CustomTableComponents = ({
  isMakingApiCall,
  columns,
  dataArray,
  handleSelectedRow,
  handleDoubleClickRow,
  numberOfRows,
  rowSelection,
  paginationMeta, // Pass pagination metadata when using a paginated API
  onPageChange,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = numberOfRows || 10;
  const showSizeChanger = !numberOfRows;
  const { t } = useTranslation();

  // Determine if API provides pagination or not
  const isPaginatedAPI = paginationMeta && paginationMeta != null;
  const totalItems = isPaginatedAPI ? paginationMeta : dataArray.length;

  return (
    <div style={{ overflowX: "auto" }}>
      <Table
       pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalItems,
          showSizeChanger,
          pageSizeOptions: ["10", "20", "50", "100"],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            if (isPaginatedAPI) {
              onPageChange(page, pageSize); // Fetch next page if API is paginated
            }
          },
        }}
        loading={{
          spinning: isMakingApiCall || false,
          indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
          tip: (
            <div>
              <h2>{t("loading")}</h2>
            </div>
          ),
        }}
        columns={columns}
        dataSource={dataArray}
        onRow={(record) => {
          return {
            onClick: () => handleSelectedRow(record),
            onDoubleClick: () => handleDoubleClickRow(record),
          };
        }}
        scroll={{ x: "max-content", y: "73vh" }}
        rowSelection={rowSelection}
        rowKey={"id"}
        //change the text of the table No Data
        locale={{
          emptyText: <Empty description={t("noData")} />,
        }}
      />
    </div>
  );
};

export default CustomTableComponents;
